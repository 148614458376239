import React, { useEffect, useState } from "react";
// import { NewseLatter } from "../component";
import Meta from "../Meta";
import Hero_6 from "../hero/hero_6";
import CoverflowCarousel from "../carousel/coverflowCarousel";
import Top_collection_hero from "../collectrions/top_collection_hero";
import Hero_Product from "../hero/hero_product";
import Footer from "../footer";
import { HeadLine } from "../component";
import Process from "../blog/process";
import {
  fetchUserDetails,
  fetchAllNfts,
  fetchCollectionsData,
  fetchTransactionsData,
} from "../../redux/data/dataSlice";
import { fetchBalanceInfo } from "../../redux/data/balanceSlice";
import { useSelector, useDispatch } from "react-redux";

const Home_1 = ({ user }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [uniqueCollections, setUniqueCollections] = useState([]);
  const [userMap, setUserMap] = useState({});
  const { userDetails, nftsData, collectionsData, transactionsData } =
    useSelector((state) => state.data);
  const { balanceInfo } = useSelector((state) => state.balance);

  useEffect(() => {
    console.log("userDetails: ");
    if (!userDetails?.length) dispatch(fetchUserDetails());
    if (!nftsData?.length) dispatch(fetchAllNfts());
    if (!collectionsData?.length) dispatch(fetchCollectionsData());
    if (!transactionsData?.length) dispatch(fetchTransactionsData());
  }, [dispatch, userDetails, nftsData, collectionsData, transactionsData]);

  useEffect(() => {
    if (userDetails?.length > 0) {
      if (!balanceInfo?.length) dispatch(fetchBalanceInfo({ userDetails }));
    }
  }, [dispatch, userDetails]);

  useEffect(() => {
    // create nftMap
    if (nftsData?.length > 0) {
      // create nft and collection
      const collections = [];
      nftsData.forEach((nft) => {
        const collection = collections.find(
          (col) => col.collectionName === nft.collectionName
        );
        if (!collection) {
          collections.push({
            collectionName: nft.collectionName,
            creatorWallet: nft.creatorWallet,
            tokens_arr: [Number(nft.token_id)],
            itemsCount: 1,
          });
        } else {
          collection.tokens_arr.push(Number(nft.token_id));
          collection.itemsCount += 1;
        }
      });
      setUniqueCollections(collections);
    }
  }, [nftsData]);

  useEffect(() => {
    const map = userDetails.reduce((acc, u) => {
      acc[u.walletAddress] = {
        username: u.username,
        name: u.name,
        profilePhotoUrl: u.profilePhotoUrl,
      };
      return acc;
    }, {});
    setUserMap(map);
  }, [userDetails]);

  useEffect(() => {
    if (uniqueCollections.length > 0 && collectionsData.length > 0) {
      const arr = [];
      let i = 0;
      for (const collection of uniqueCollections) {
        const collectionData = collectionsData.find(
          (data) =>
            data.collection_name === collection.collectionName &&
            data.collection_owner_wallet === collection.creatorWallet
        );
        const collectionTransactions = transactionsData.filter(
          (transaction) =>
            collection.tokens_arr.includes(transaction.tokenId) &&
            transaction.eventName === "transfer"
        );
        const volume = collectionTransactions
          .reduce((acc, transaction) => {
            return acc + Number(transaction.price);
          }, 0)
          .toFixed(2);
        const items = collection.itemsCount;
        const isVerified = collectionData?.isVerified;
        const obj = {
          id: i,
          collectionImage: collectionData?.collection_image,
          collectionName: collectionData?.collection_name,
          creatorUserName:
            userMap[collectionData?.collection_owner_wallet]?.username,
          creatorName: userMap[collectionData?.collection_owner_wallet]?.name,
          creatorImage:
            userMap[collectionData?.collection_owner_wallet]?.profilePhotoUrl,
          volume,
          items,
          icon: isVerified,
        };
        arr.push(obj);
        i++;
      }
      arr.sort((a, b) => b.volume - a.volume);
      setData(arr);
    }
  }, [uniqueCollections, collectionsData, transactionsData, userMap]);

  return (
    <main className="bg-[#000000] relative flex flex-col gap-32">
      <Meta title="home" />
      {/* <Image
        src="/giphy.webp"
        alt="A gif of a person dancing"
        width={500}
        height={500}
        className="fixed top-0 left-0 w-full h-full z-[0] object-cover"
      /> */}

      {/* <iframe
        src="https://giphy.com/embed/LpGTkDbFtLQt5J6LGL"
        width="100%"
        height="100%"
        style={{ position: "fixed" }}
        className="giphy-embed"
        allowFullScreen
      ></iframe> */}
      <Hero_Product
        transactionsData={transactionsData}
        userMap={userMap}
        nftsData={nftsData}
      />
      <Top_collection_hero data={data.slice(0, 12)} />
      {/*<Bids />*/}

      {/* <Hero_5 /> */}
      <div>
        <div className="flex justify-center items-center text-3xl mb-10">
          <HeadLine text="NOTABLE COLLECTIONS" classes="inline text-white" />
        </div>
        <CoverflowCarousel data={data} />
      </div>

      <div>
        <div className="flex justify-center items-center text-3xl mb-10">
          <HeadLine text="EXPENSIVE SALE" classes="inline text-white" />
        </div>
        <Hero_6
          transactionsData={transactionsData}
          userMap={userMap}
          nftsData={nftsData}
        />
      </div>
      {/* <h1 className="my-16 mb-24 text-center font-russo text-3xl text-jacarta-700 dark:text-white">
            <HeadLine text="Create and Sell Nfts" classes="inline text-white" />
          </h1> */}

      {/* <Hero_13 /> */}
      {/* <Image
        src="/nftoscoin.jpg"
        alt="hero_13"
        width={1920}
        height={1080}
        className="w-full md:w-[70%] h-auto mx-auto object-contain my-16"
      /> */}
      <Process />
      <Footer />

      {/* <NewseLatter /> */}
    </main>
  );
};

export default Home_1;
