import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";

const Tag = () => {
  const { scrollY } = useViewportScroll(); // Framer Motion's scroll listener
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  // Parallax Effect: Controls text's Y position during scrolling
  const yOffset = useTransform(scrollY, [0, 300], [0, -50]);

  // Mousemove Event Listener
  const handleMouseMove = (e) => {
    setCursorPosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="relative flex justify-center items-center h-[150vh] bg-black overflow-hidden">
      {/* Parallax Motion Container */}
      <motion.div
        className="text-center text-white font-bold"
        style={{ y: yOffset }} // Applies parallax effect
      >
        <motion.h1
          className="text-3xl md:text-6xl lg:text-8xl space-y-4"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
          // whileHover={{
          //   textShadow: `34px 23px ${
          //     0 + cursorPosition.x / 300
          //   }px rgba(206, 206, 206, 0.9), 0px 0px ${
          //     1 + cursorPosition.y / 100
          //     }px rgba(255, 183, 157, 0.8)`,
          //   rotate: cursorPosition.x / 100,
          // }}
        >
          <motion.span
            className="block"
            whileHover={{
              scale: 1.2,
            }}
            style={{
              fontSize: `${80 + cursorPosition.x / 100}px`,
            }}
          >
            Embrace the revolution
          </motion.span>
          <motion.span
            className="block"
            whileHover={{
              scale: 1.2,
            }}
            style={{
              fontSize: `${80 + cursorPosition.y / 100}px`,
            }}
          >
            and unlock the full potential
          </motion.span>
          <motion.span
            className="block"
            whileHover={{
              scale: 1.2,
            }}
            style={{
              fontSize: `${80 + (cursorPosition.x + cursorPosition.y) / 200}px`,
            }}
          >
            of your digital assets with us!
          </motion.span>
        </motion.h1>
      </motion.div>
    </div>
  );
};

export default Tag;
