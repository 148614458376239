import Link from "next/link";
import React, { useEffect, useState } from "react";
import { hero_6_data } from "../../data/hero_6_data";
import Image from "next/image";
import { eventNames } from "process";

const Hero_6 = ({ transactionsData, userMap, nftsData }) => {
  const [nftRankedTokens, setNftRankedTokens] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Filter unique token_ids and rank them based on price
    const tempTransactions = transactionsData
      .slice(0)
      .filter(
        (transaction) =>
          Number(transaction.price) > 0 && transaction.eventName === "transfer"
      )
      .sort((a, b) => {
        return Number(b.price) - Number(a.price);
      });
    // remove duplicate tokens ... keep the highest price
    const uniqueTokenIds = [];
    tempTransactions.forEach((transaction) => {
      if (!uniqueTokenIds.includes(transaction.tokenId)) {
        uniqueTokenIds.push(transaction.tokenId);
      }
    });
    // rank the unique tokens based on price
    setNftRankedTokens(uniqueTokenIds);
  }, [transactionsData]);

  useEffect(() => {
    const fetchNftData = () => {
      nftRankedTokens.slice(0, 9).forEach((tokenId) => {
        const data = nftsData.find((nft) => nft.token_id == tokenId);
        if (data) {
          const obj = {
            id: data.token_id,
            nftName: data.nftName,
            nftImage: data.nftImage,
            collectionName: data.collectionName,
            creatorWallet: data.creatorWallet,
          };
          if (!nftData.find((nft) => nft.id === obj.id)) {
            setNftData((prev) => [...prev, obj]);
          }
        }
      });
    };
    fetchNftData();
  }, [nftRankedTokens, nftsData, nftData]);

  useEffect(() => {
    const finalData = [];
    const uniqueTokenIds = [];
    for (let i = 0; i < nftData.length; i++) {
      if (!uniqueTokenIds.includes(nftData[i].id)) {
        uniqueTokenIds.push(nftData[i].id);
        finalData.push(nftData[i]);
      }
    }

    setData([...finalData, ...hero_6_data].slice(0, 9));
  }, [nftData]);

  useEffect(() => {
    console.log("nftDatainhero6: ", nftData);
  }, [nftData]);

  return (
    <>
      {/* <!-- Hero --> */}
      <section className="relative   bg-transparent">
        <div className="flex flex-col gap-5 lg:flex-row">
          <div className="w-full lg:w-1/3">
            <div className="grid grid-cols-2 grid-rows-2 gap-5">
              {data.slice(0, 4).map((item, index) => {
                const { id, nftName, nftImage, creatorWallet, collectionName } =
                  item;
                const title = nftName,
                  img = nftImage,
                  authorName = creatorWallet;
                // const itemLink = {
                //   pathname: `/nftPage/${item.id}`,
                //   query: {
                //     image: item.nftImage || "",
                //     name: item.nftName || "Unnamed NFT",
                //     ownerAddress: item.ownerAddress || "",
                //     ownerCount: item.ownerCount || 0,
                //     externalLink: item.external_link || "",
                //     amount: item.balance || 0,
                //     supply: item.supply || 0,
                //     royalty: item.royalty || 0,
                //     description: item.description || "",
                //   },
                // };
                const itemLink =
                  collectionName && creatorWallet
                    ? `/collection/${userMap[creatorWallet]?.username}/${collectionName}`
                    : "#";
                return (
                  <article key={index}>
                    <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700">
                      <figure className="relative">
                        <Link
                          href={itemLink}
                          className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20"
                        >
                          <Image
                            width={470}
                            height={470}
                            src={img}
                            alt={title}
                            className="w-full object-cover transition-transform duration-[1600ms] will-change-transform aspect-square group-hover:scale-105"
                          />
                        </Link>
                      </figure>
                      <div className="pointer-events-none absolute bottom-0 w-full p-5">
                        <h2 className="font-russo text-base leading-none text-white xl:text-lg">
                          {title}
                        </h2>
                        <span className="text-2xs text-white">
                          {userMap[authorName]?.name
                            ? userMap[authorName]?.name
                            : authorName}
                        </span>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
          <div className="w-full lg:w-1/3">
            {data.slice(4, 5).map((item, index) => {
              const { id, nftName, nftImage, creatorWallet, collectionName } =
                item;
              const title = nftName,
                img = nftImage,
                authorName = creatorWallet;

              const itemLink =
                collectionName && creatorWallet
                  ? `/collection/${userMap[creatorWallet]?.username}/${collectionName}`
                  : "#";
              return (
                <article key={index}>
                  <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700">
                    <figure className="relative">
                      <Link
                        href={itemLink}
                        className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20"
                      >
                        <Image
                          width={470}
                          height={470}
                          src={img}
                          alt={title}
                          className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105 aspect-square"
                        />
                      </Link>
                    </figure>
                    <div className="pointer-events-none absolute bottom-0 w-full p-5">
                      <h2 className="font-russo text-base leading-none text-white xl:text-lg">
                        {title}
                      </h2>
                      <span className="text-2xs text-white">
                        {userMap[authorName]?.name
                          ? userMap[authorName]?.name
                          : authorName}
                      </span>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
          <div className="w-full lg:w-1/3">
            <div className="grid grid-cols-2 grid-rows-2 gap-5">
              {data.slice(5, 9).map((item, index) => {
                const { id, nftName, nftImage, creatorWallet, collectionName } =
                  item;
                const title = nftName,
                  img = nftImage,
                  authorName = creatorWallet;
                const itemLink =
                  collectionName && creatorWallet
                    ? `/collection/${userMap[creatorWallet]?.username}/${collectionName}`
                    : "#";
                return (
                  <article key={index}>
                    <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700">
                      <figure className="relative">
                        <Link
                          href={itemLink}
                          className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20"
                        >
                          <Image
                            width={470}
                            height={470}
                            src={img}
                            alt={title}
                            className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105 aspect-square"
                          />
                        </Link>
                      </figure>
                      <div className="pointer-events-none absolute bottom-0 w-full p-5">
                        <h2 className="font-russo text-base leading-none text-white xl:text-lg">
                          {title}
                        </h2>
                        <span className="text-2xs text-white">
                          {userMap[authorName]?.name
                            ? userMap[authorName]?.name
                            : authorName}
                        </span>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero_6;
