import React from "react";
import { motion } from "framer-motion";

const features = [
  "Diverse NFTs Market",
  "0 Commission Fees",
  "Real-World Utility via Tokens.",
  "Innovative Digital Asset Interaction.",
  "User-Friendly Experience.",
  "Integrated Metaverse Features",
];

const FeatureSection = () => {
  return (
    <div className="bg-black text-white px-4 z-10 flex justify-center my-[8rem] py-16">
      <div className="max-w-5xl">
        <h2 className="text-center text-3xl md:text-4xl font-bold mb-8">
          What Drives Us
        </h2>
        <div className="flex flex-wrap justify-center gap-4">
          {features.map((feature, index) => (
            <motion.div
              key={feature}
              style={{ boxShadow: "0px 8px 15px rgba(255, 0, 0, 0.5)" }}
              className="text-[#7d8590] flex items-center justify-center px-6 py-3 text-center text-lg md:text-xl border border-red-500 rounded-full shadow-lg shadow-red-500/50 cursor-pointer transition-transform duration-300
                                   flex-basis-1/2 sm:flex-basis-1/3 md:flex-basis-1/4 lg:flex-basis-1/5"
              initial={{
                opacity: 0,
                x: index % 2 === 0 ? -150 : 150, // Left or Right
              }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              exit={{
                opacity: 0,
                x: index % 2 === 0 ? -100 : 100, // Exit same direction as entry
              }}
              viewport={{ once: false, amount: 0.2 }}
              transition={{
                type: "spring",
                stiffness: 300, // Adjust stiffness to control the springiness
                damping: 20, // Adjust damping to control the bounce
                delay: index * 0.2, // Stagger effect
                duration: 0.6,
              }}
              whileHover={{
                scale: 1.1,
                boxShadow: "0px 8px 15px rgba(255, 0, 0, 0.5)",
              }}
            >
              {feature}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
