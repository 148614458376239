/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState, useRef } from "react";
import Meta from "../Meta";
import Hero_landing from "../hero/hero_landing";
import Benifits from "../dao/Benifits";
import Testimonial from "../testimonial/Testimonial";
import Process from "../blog/process";
import Participate from "../dao/Participate";
import NewseLatter2 from "../dao/newseLatter2";
import Services from "../services/services";
import Link from "next/link";
import AnimationPoints from "./AnimationPoints";
import Herolanding2 from "./Herolanding2";
import Image from "next/image";
import Alert from "../alert";
import { motion } from "framer-motion";
import ExploreMarket from "./MarketCard";
import About from "./About";
import FeatureSection from "./FeatureSection";
import PricingSection from "./PricingSection";
import Tag from "./Tag";

const Landing = () => {
  return (
    <div className="bg-[#000000] ">
      <Meta title="home" />

      <motion.div>
        <Alert />
        {/* <Hero_landing /> */}
        <motion.div className="overflow-hidden pb-24">
          <motion.div className="hero-section px-3 ">
            <Herolanding2 />
          </motion.div>
          <About />
          <ExploreMarket />
          <FeatureSection />
          <Tag />
          <PricingSection />

          <div className="h-[50vh] md:h-[70vh] flex flex-col mx-auto px-8 max-w-[1200px] justify-center items-center">
            <div className="flex flex-col items-center justify-center border copilot border-[#141414] p-8 md:px-28 md:py-8 gap-3 rounded-full">
              <Image
                src="/favicon.png"
                alt="Picture of the author"
                width={100}
                height={100}
                className="w-[75px] md:w-[100px] h-auto"
              />
              <p className="text-2xl md:text-5xl font-bold">Start With nftOS</p>
              <p className="text-center text-md md:text-lg text-[#7d8590] mb-6">
                First Unified NFTs Marketplace in the Metaverse
              </p>
              <Link href="/login" className="cursor-pointer">
                <button className="btn-grad text-white transition-all px-6 py-3 rounded-full cursor-pointer">
                  Get Started
                </button>
              </Link>
            </div>
          </div>
        </motion.div>
        <footer className="flex w-full justify-between border-t border-accent px-4 py-8 pt-4 items-center">
          <ul className="text-white flex flex-wrap space-x-4 text-sm">
            <li>
              <a
                href="https://trillicorntechnology.company/"
                target="_blank"
                className=""
              >
                © 2025 Trillicorn Technology
              </a>
            </li>
          </ul>
          <ul className="flex gap-3 invert">
            <a href="https://x.com/nftOS2024">
              <Image
                width={120}
                height={50}
                src="/twitter.svg"
                alt=""
                className="text-[#686868] w-6 h-auto"
              />
            </a>
            <a href="https://www.linkedin.com/showcase/nftos-marketplace/">
              <Image
                width={120}
                height={50}
                src="/linkedin.svg"
                alt=""
                className="w-6 h-auto"
              />
            </a>
          </ul>
        </footer>
      </motion.div>
    </div>
  );
};

export default Landing;
// {
/*
      <div className="form-wrapper min-h-screen flex justify-center items-center bg-cover bg-center">
        <div className="form-container backdrop-blur-lg w-screen h-screen lg:w-[80vw] lg:h-[90vh] p-8 lg:p-20 rounded-2xl  text-[#1A2421] bg-gradient-to-b from-white/60 to-white/30  border-[1px] border-solid border-white border-opacity-30 shadow-black/70 shadow-2xl flex items-center justify-center flex-col">
          <div className="max-w-md flex items-center justify-center flex-col">
            <h1 className="text-3xl  lg:text-5xl font-extrabold mb-8">
              Don&apos;t Wait. Become Our Member Today.
            </h1>
            <button className=" hover:bg-accent transition-all text-white px-4 py-1 rounded-md hover:border-white hover:border-[1px] underline hover:no-underline">
              Get started
            </button>
          </div>
        </div>
      </div> */
// }
