import React from "react";

const MarketCard = ({ title, description, image }) => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between bg-black text-white border-shadow p-6 rounded-lg space-y-4 md:space-y-0">
      <div className="md:w-3/5 space-y-4">
        <h2 className="text-2xl font-bold text-red-500">{title}</h2>
        <p className="text-base leading-relaxed text-[#7d8590]">
          {description}
        </p>
      </div>
      <div className="md:w-2/5">
        <img
          src={image}
          alt={`${title} preview`}
          className="rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
};

const ExploreMarket = () => {
  return (
    <div className="bg-black flex flex-col items-center space-y-8 px-4 my-[8rem]">
      {/* Explore Button */}
      <h2 className="text-center text-3xl md:text-4xl font-bold mb-8">
        Explore Market
      </h2>

      {/* Market Cards */}
      <div className="space-y-8 w-full max-w-5xl">
        <MarketCard
          title="ProductGallery"
          description="There You Get a diverse range of NFTs categorized by real-world consumer brands and individual creators, making it a comprehensive platform for discovering and trading NFTs."
          image="/images/hero/visual-product.png"
        />
        <MarketCard
          title="PropertyWorld"
          description="We brings you a unique collection of property-based NFTs from real estate companies and creators. Experience virtual ownership, explore immersive 3D properties, and discover new ways to buy, sell, or monetize real estate in the digital world."
          image="/images/hero/visual-property.png"
        />
      </div>
    </div>
  );
};

export default ExploreMarket;
