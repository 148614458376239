/* eslint-disable @next/next/no-img-element */
import React from "react";
import Link from "next/link";
import HeadLine from "../headLine";
import Image from "next/image";

const Top_collection_hero = ({ data }) => {
  return (
    <div>
      {/*dark:bg-jacarta-800*/}
      <section className=" relative">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <Image
            width={1519}
            height={773}
            priority
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full object-cover"
          />
        </picture>
        <div className="container">
          <div className=" text-white mb-10 text-center text-lg sm:text-3xl dark:text-black flex justify-center items-center gap-x-3">
            <HeadLine text="TOP COLLECTIONS" classes="inline text-white" />
          </div>
          <div className="min-h-[300px]">
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-4">
              {data.map((item, index) => {
                const {
                  collectionName,
                  collectionImage,
                  creatorUserName,
                  volume,
                  icon,
                } = item;
                const itemLink = `/collection/${creatorUserName}/${collectionName}`;

                return (
                  <div
                    className="  rounded-2xl flex border-none bg-[#0a0a0a] py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent"
                    key={index}
                  >
                    <figure className="mr-4 shrink-0">
                      <Link href={itemLink} className="relative block">
                        <Image
                          src={collectionImage}
                          alt={collectionName}
                          className="rounded-2lg"
                          height={48}
                          width={48}
                        />
                        <div className="dark:border-jacarta-600 bg-[#111111] absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white text-xs text-white">
                          {index + 1}
                        </div>
                        {icon && (
                          <div
                            className="dark:border-jacarta-600 bg-green absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white"
                            data-tippy-content="Verified Collection"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              className="h-[.875rem] w-[.875rem] fill-white"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                            </svg>
                          </div>
                        )}
                      </Link>
                    </figure>
                    <div>
                      <Link href={itemLink} className="block">
                        <span className="font-russo text-white hover:text-accent font-semibold dark:text-white">
                          {collectionName}
                        </span>
                      </Link>
                      <span className="text-white dark:text-jacarta-300 text-sm">
                        {volume} ETH
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-10 text-center short-btn">
            <Link
              href="/rankings"
              className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center text-white transition-all btn-grad"
            >
              Go to Rankings
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Top_collection_hero;
