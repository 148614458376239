import React, { useState } from "react";
import Image from "next/image";
import Link from "next/link";
const Herolanding2 = () => {
  return (
    <div className="relative max-w-[1280px] px-4 md:px-10 mx-auto">
      {/* <img className='absolute hero-drone top-28 w-1/4 h-auto right-0 xl:right-0' width="500" height="326" src="https://github.githubassets.com/images/modules/site/home-campaign/hero-drone.webp" alt="" /> */}
      <div className="flex w-full">
        <div className="relative ">
          <div className="relative h-[300px]">
            <svg
              viewBox="0 0 518 515"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute top-0 left-[-130px] md:left-[-140px] h-[300px] md:w-auto"
            >
              <path
                d="M257.627 2.99991C199.674 50.2668 150.913 141.571 226.055 233.7L280.368 300.292C330.057 361.214 323.538 479.182 283.886 511.523"
                stroke="url(#paint0_linear_766_44)"
                strokeWidth="7"
                strokeLinecap="round"
              />
              <path
                d="M256.481 2.99994C317.451 55.6503 370.403 151.722 296.264 237.576L242.676 299.633C193.65 356.407 204.118 475.499 245.835 511.523"
                stroke="url(#paint1_linear_766_44)"
                strokeWidth="7"
                strokeLinecap="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_766_44"
                  x1="308.385"
                  y1="676.645"
                  x2="534.302"
                  y2="74.5909"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#7E0000" />
                  <stop offset="0.00733964" stopColor="#81001D" />
                  <stop offset="0.251918" stopColor="#0C0002" />
                  <stop offset="0.325848" stopColor="#9A0014" />
                  <stop offset="0.945217" stopColor="#030100" />
                  <stop offset="1" stopColor="#CE1A06" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_766_44"
                  x1="225.992"
                  y1="675.222"
                  x2="43.0842"
                  y2="30.9073"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#7E0000" />
                  <stop offset="0.00733964" stopColor="#81001D" />
                  <stop offset="0.251918" stopColor="#0C0002" />
                  <stop offset="0.325848" stopColor="#9A0014" />
                  <stop offset="0.945217" stopColor="#030100" />
                  <stop offset="1" stopColor="#CE1A06" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>

          <div className="mx-auto my-3 relative">
            <span className="absolute -left-3 top-0 z-1">
              <Image
                src={"/favicon.png"}
                alt="logo"
                height={30}
                width={30}
                className="w-[50px] h-auto opacit"
              />

              {/* <span
                className="absolute left-3 -z-1 top-0 w-6 h-full opacity-30  home-campaign-glowing-icon-glow "
                style={{
                  backgroundColor: "var(--mktg-accent-primary)",
                  filter: "blur(17px)",
                }}
              ></span> */}
            </span>
            <div className="h-[50px] w-[50px]"></div>
          </div>
          <div
            style={{
              background:
                "linear-gradient(#000000, #2e0909 10%, #791919 70%, #b52828 80%, #A11011)",
              marginLeft: "11px",
            }}
            className=" max-md:w-[2px] w-[3px] h-[400px] rounded-md"
          ></div>
        </div>
        {/* <div className="flex flex-wrap"> */}
        <div className="w-full flex flex-col items-center md:block absolute pt-12 mt-28 max-md:px-4 ml-4 md:ml-24">
          {/* <div className="relative md:top-20 mb-[24px]">
            <a
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              href=""
              className=" border-[1px] border-neutral-600 copilot rounded-full inline-block"
            >
              <div className="flex items-center p-3 px-5">
                <img
                  className=" d-block w-auto flex-grow-0 flex-shrink-0 mr-6 max-md:mr-3 scale-110"
                  width="44"
                  height="44"
                  loading="lazy"
                  decoding="async"
                  alt=""
                  aria-hidden="true"
                  src="https://github.githubassets.com/images/modules/site/eyebrow-banner-icon-copilot-x.svg"
                />
                <div className="md:pr-5 pr-3 md:mr-2 md:pl-2">
                  <div className="font-medium text-white text-[16px] max-md:text-[14px] leading-5">
                    Introducing GitHub Copilot X
                  </div>
                  <div className="text-neutral-500 max-md:text-[14px] text-[16px]">
                    Your Ai pair programmer is leveling Up
                  </div>
                </div>
                <div className="ml-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`  text-white transition ease-in duration-150 ${
                      hovered ? "translate-x-0 " : "-translate-x-1"
                    }`}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"
                    ></path>
                    <path
                      className={` text-white transition ease-in duration-150 ${
                        hovered ? " opacity-100" : "opacity-0 "
                      }`}
                      stroke="currentColor"
                      d="M1.75 8H11"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    ></path>
                  </svg>
                </div>
              </div>
            </a>
          </div> */}
          <div className="flex flex-col-reverse md:flex-row items-center justify-center md:justify-start gap-4 w-full mt-28">
            <div className="flex flex-col items-center md:block">
              <h1 className="relative z-2 max-md:mb-5 text-[48px] md:text-[72px] max-sm:leading-[60px] max-md:leading-[80px] lg:text-[80px] font-semibold text-[#ffffff]">
                {" "}
                World's First
              </h1>
              <p className="relative z-1 text-[24px] md:text-[28px]  lg:text-[32px] leading-[30px] md:leading-[36px] lg:leading-[44px] mb-5 md:mb-12 md:10/12  lg:w-9/12 text-[#7d8590] text-center md:text-start">
                Unified NFTs Marketplace in the Metaverse
              </p>
            </div>
            <Image
              src="/logo.png"
              alt="Picture of the author"
              width={500}
              height={500}
              className="w-[200px] md:w-[300px] lg:w-[400px] h-auto"
            />
          </div>
          <div className="flex mt-4 justify-center md:justify-start">
            <Link href="/login" className="cursor-pointer">
              <button className="btn-grad text-white transition-all px-6 py-3 rounded-full cursor-pointer font-bold">
                Get Started
              </button>
            </Link>
          </div>
          {/* <div className="md:my-32 my-24">
            <p className="text-[16px] leading-[24px] text-[#7d8590]">
              Trusted by the world&apos;s leading&nbsp;organizations&nbsp;↘︎
            </p>
            <div className="flex flex-wrap justify-between items-center">
              <img
                src="https://github.githubassets.com/images/modules/site/home-campaign/logos/stripe.svg"
                alt="Stripe logo"
                height="44"
                className="my-3 scale-90"
              />
              <img
                src="https://github.githubassets.com/images/modules/site/home-campaign/logos/pinterest.svg"
                alt="Pinterest logo"
                height="44"
                className="my-3 scale-90"
              />
              <img
                src="https://github.githubassets.com/images/modules/site/home-campaign/logos/kpmg.svg"
                alt="KPMG logo"
                height="44"
                className="my-3 scale-90"
              />
              <img
                src="https://github.githubassets.com/images/modules/site/home-campaign/logos/mercedes.svg"
                alt="Mercedes-Benz logo"
                height="44"
                className="my-3 w-[200px]"
              />
              <img
                src="https://github.githubassets.com/images/modules/site/home-campaign/logos/pg.svg"
                alt="P&amp;G logo"
                height="32"
                className="my-3 scale-90"
              />
              <img
                src="https://github.githubassets.com/images/modules/site/home-campaign/logos/telus.svg"
                alt="Telus logo"
                height="32"
                className="my-3 scale-90"
              />
            </div>
          </div> */}
        </div>
        {/* <Image
            src="/logo.png"
            alt="Picture of the author"
            width={500}
            height={500}
            className="h-[500px] w-[500px]"
          /> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Herolanding2;
