import React, { useState, useRef } from "react";
import { motion } from "framer-motion";

const About = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  const handleMouseMove = (event) => {
    const rect = imageRef.current.getBoundingClientRect();
    const x = (event.clientX - rect.left) / rect.width;
    const y = (event.clientY - rect.top) / rect.height;

    setMousePosition({ x, y });
  };

  const handleMouseLeave = () => {
    setMousePosition({ x: 0.5, y: 0.5 });
  };

  const fadeInVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
    exit: { opacity: 0, y: -30, transition: { duration: 0.8 } },
  };

  return (
    <motion.div
      className="bg-black min-h-screen flex flex-col items-center justify-center p-6 space-y-10 text-white"
      key="about-text"
      variants={fadeInVariant}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.5 }}
    >
      <motion.div className="max-w-4xl text-center">
        <h2 className="text-2xl md:text-4xl font-bold mb-6">
          It's a metaverse-based NFTs marketplace
        </h2>
        <p className="text-sm md:text-lg leading-relaxed text-[#7d8590]">
          This platform combines all NFT market services into one, empowering
          you to create, buy, sell, and trade digital assets effortlessly in a
          unique and modern digital experience.
        </p>
      </motion.div>

      <motion.div
        key="about-image"
        className="relative border-4 border-accent rounded-lg p-4"
        variants={fadeInVariant}
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.1 }}
      >
        <motion.img
          ref={imageRef}
          src="/images/hero/landing-page.png"
          alt="NFT Marketplace Preview"
          className="w-full h-auto rounded-md shadow-lg"
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          style={{
            transform: `perspective(1000px) rotateX(${
              (mousePosition.y - 0.5) * 20
            }deg) rotateY(${(mousePosition.x - 0.5) * -20}deg)`,
            transition: "transform 0.1s ease-out",
          }}
        />
      </motion.div>
    </motion.div>
  );
};

export default About;
