import React from "react";
import Image from "next/image";
import Link from "next/link";

const PricingSection = () => {
  return (
    <section className="flex flex-col gap-12 lg:gap-24 h-full text-white relative overflow-hidden mb-24">
      <Image
        src="/giphy.webp"
        alt="A gif of a person dancing"
        width={500}
        height={500}
        className="fixed top-0 left-0 w-full h-[100vh] z-[-1] object-cover"
        quality={75}
        loading="lazy"
      />
      <div
        className="p-4 mt-16 lg:mt-16 lg:mb-28 sm:px-6 flex flex-col justify-center items-center text-base h-full mx-auto w-full"
        id="pricing"
      >
        <div className="text-2xl sm:text-3xl lg:text-4xl tracking-wider font-extrabold text-center mb-6 lg:mb-12">
          <p>Simple, Transparent & Flexible</p>
          <p className="gradient-text2">Pricing For You.</p>
        </div>

        <div className="isolate mx-auto grid w-full max-w-[450px] lg:max-w-[850px] h-full grid-cols-1 gap-12 lg:gap-28 lg:mx-0 lg:grid-cols-2">
          {/* Basic Plan */}
          <div className="border-2 border-accent rounded-3xl p-6 sm:p-8 xl:p-10 flex flex-col justify-between h-full">
            <div className="flex flex-col">
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id="tier-standard"
                  className="text-[#A9A9A9] text-xl sm:text-2xl font-semibold leading-8"
                >
                  Basic
                </h3>
              </div>

              <p className="mt-4 sm:mt-6 flex items-baseline gap-x-1">
                <span className="text-lg sm:text-3xl font-bold tracking-tight text-gray-900">
                  Free For Everyone
                </span>
              </p>

              <ul
                role="list"
                className="mt-6 sm:mt-8 space-y-3 text-sm sm:text-base leading-6 text-gray-600"
              >
                <li className="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-6 w-6 flex-none"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  0 Commission Charge
                </li>
                <li className="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-6 w-6 flex-none"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  1k Collection Limit
                </li>
                <li className="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-6 w-6 flex-none"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  24/7 Support
                </li>
              </ul>
            </div>
            <Link
              href="/login"
              className="hover-btn-grad cursor-pointer border-2 border-accent hover:border-[#000000] rounded-full py-3 px-6 sm:px-8 transition-all ease-in-out duration-300"
            >
              <p className="text-white text-center font-semibold">Start</p>
            </Link>
          </div>
          {/* Premium Plan */}
          <div className="border-shadow rounded-3xl p-6 sm:p-8 xl:p-10 text-[#A9A9A9] flex flex-col gap-4 justify-between h-full">
            <div className="flex flex-col">
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id="tier-extended"
                  className="text-xl sm:text-2xl font-semibold leading-8"
                >
                  Premium
                </h3>
              </div>

              <p className="mt-4 sm:mt-6 flex items-baseline gap-x-1">
                <span className="text-lg sm:text-3xl font-bold tracking-tight text-gray-900">
                  $$/Year
                </span>
              </p>

              <ul
                role="list"
                className="mt-6 sm:mt-8 space-y-3 text-sm sm:text-base leading-6 text-gray-600"
              >
                <li className="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-6 w-6 flex-none"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  0% Commission
                </li>
                <li className="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-6 w-6 flex-none"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  Collection booster
                </li>
                <li className="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-6 w-6 flex-none"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  Dashboard
                </li>
                <li className="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-6 w-6 flex-none"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  Advance Features Access
                </li>
              </ul>
            </div>
            <div
              aria-describedby="tier-standard"
              className="bg-accent-lighter rounded-full py-3 px-6 sm:px-8 text-center font-semibold text-white transition-all btn-grad"
              target="_blank"
            >
              Coming Soon
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
